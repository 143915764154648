export const Team = (props) => {
  return (
    <div id='team' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Meet our Team</h2>
          <p>
            Get acquainted with the experts behind our success. Our dedicated team comprises experienced professionals who bring a wealth of knowledge and skills to the table. From industry veterans to emerging talents, we collaborate seamlessly to deliver exceptional results for our clients. Together, we are committed to excellence, innovation, and exceeding expectations.
          </p>
        </div>
        <div id='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-3 col-sm-6 team'>
                  <div className='thumbnail'>
                    {' '}
                    <img src={d.img} alt='...' className='team-img' />
                    <div className='caption'>
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
